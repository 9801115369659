import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

import "./NavbarFixed.css";

function NavbarFixed() {
  return (
    <div className="nav-container">
      <Navbar className=" text-white">
        <Container>
          <Nav>
            <Nav.Link className="navText px-3 fs-6 text-white " href="/">
              Home
            </Nav.Link>
            <Nav.Link className="navText text-white px-3 fs-6 " href="/about">
              About me
            </Nav.Link>
            <Nav.Link
              className="navText text-white px-3 fs-6 "
              href="/projects"
            >
              Projects
            </Nav.Link>
          </Nav>
        </Container>
      </Navbar>
    </div>
  );
}

export default NavbarFixed;
