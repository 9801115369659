import { useEffect, useState } from "react";
import "./Weather.css";

import React from "react";

function Weather() {
  const [data, setData] = useState();
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const [locations, setLocations] = useState("Stockholm");
  const [click, setClick] = useState();
  const [temp, setTemp] = useState();

  useEffect(() => {
    function fetchData() {
      fetch(
        `https://api.weatherapi.com/v1/current.json?key=f1d445cc67e2496a8be133433221805&q=${locations}&aqi=nos`
      )
        // .then((response) => response.json())
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error();
          }
        })
        .then(setData)
        .catch(setError)

        .finally(() => setLoading(false));
    }

    fetchData();
  }, [locations]);

  useEffect(() => {
    data && setTemp(data.current.temp_c);
  }, [data]);

  const handleInputChange = (event) => {
    setClick(event.target.value);
  };
  const handleClick = (event) => {
    event.preventDefault();
    setLocations(click);
  };

  const arrangedData = () => {
    return (
      <div>
        <div>
          {data ? (
            <div>
              <div className="paragraph">
                <div className="text-weather">
                  {data.location.name} , {data.location.country} {temp + " C"}
                </div>
                <img
                  className="sun"
                  src={data.current.condition.icon}
                  alt="weather condition"
                />
              </div>
              <div>
                <input
                  className="inputField"
                  type="text"
                  placeholder=" Search location.."
                  onChange={handleInputChange}
                />
                {locations ? (
                  <button
                    className="weatherWidgetButtons"
                    type="submit"
                    onClick={handleClick}
                  >
                    Submit
                  </button>
                ) : (
                  (alert("Please enter location :-)"), setLocations("Chisinau"))
                )}
              </div>
            </div>
          ) : (
            "loading..."
          )}
        </div>
      </div>
    );
  };

  return (
    <div>
      {loading ? (
        "loading"
      ) : error ? (
        <div className="error">'Error occured, try to refresh the page'</div>
      ) : (
        <div className="title-weather">
          <div className="w-title"> Weather </div>
          {arrangedData()}
        </div>
      )}
    </div>
  );
}

export default Weather;
