import React from "react";
import Personal from "../../components/aboutComponent/Personal";
import Skills from "../../components/skills/Skills";

function About() {
  return (
    <div>
      <Personal />
      <Skills />
    </div>
  );
}

export default About;
