import React from "react";
import "../skills/Skills.css";
function Education() {
  return (
    <div className="education-container">
      {/*---------------- Experience---------- */}
      <div className="col-12  text-center round-skills  ">
        <h5 className="title-skills  mb-5 mt-1">Experience</h5>
        <div className="all-square text-center">
          {/* border */}
          <div className="my-2 experience-box text-center">
            <div className="square mb-3">02/2022 - present</div>
            <span className="square-text  pt-2 ps-2 mx-auto">
              Fullstack Developer/ Freelancer, Sweden
            </span>
          </div>
          <div className="my-2 experience-box text-center">
            <div className="square mb-3">09/2021 - 02/2022 </div>
            <span className="square-text  pt-2 ps-2  mx-auto">
              NexTek , Front-end Developer, Sweden
            </span>
          </div>
          <div className="my-2 experience-box text-center">
            <div className="square mb-3">09/2020 - 09/2021</div>
            <span className="square-text  pt-2 ps-2 mx-auto">
              Front-end Developer/ Freelancer, Sweden
            </span>
          </div>
          {/* EDUCATION -------------------*/}

          <h5 className="title-skills  mb-5 mt-5">Education</h5>
          <div className="all-round text-center ">
            {/* border */}
            <div className="round-each-box">
              <div className="round my-3">09/2023 - 03/2024 </div>
              <span className="ps-3">
                System Developer Python and IT security/Lexicon, Stockholm
              </span>{" "}
            </div>

            {/* border */}
            <div className="round-each-box">
              <div className="round my-3">10/2022 - 03/2023 </div>
              <span className="ps-3">
                International Full Stack Trainee Program / Integrify INT-FS6,
                Stockholm
              </span>{" "}
            </div>

            <div className="border-side"></div>
            {/* border */}
            <div className="round-each-box">
              <div className="round my-3">2022</div>
              <span className="ps-3">
                Udemy,The Complete JavaScript Course 2023, Stockholm
              </span>
            </div>

            <div className="border-side"></div>
            {/* border */}
            <div className="round-each-box">
              {" "}
              <div className="round my-3">2021</div>
              <span className="ps-3">
                Udemy,The Ultimate Fullstack Web Development Bootcamp, Stockholm
              </span>
            </div>

            <div className="border-side"></div>
            {/* border */}
            <div className="round-each-box">
              <div className="round my-3">2020</div>
              <span className="ps-3">
                Udemy,Lua Programming and Game Development with LÖVE, Stockholm
              </span>
            </div>

            <div className="border-side"></div>
            {/* border */}
            <div className="round-each-box">
              <div className="round my-3">2000 - 2005</div>
              <span className="ps-3">
                Moldova State University, Chisinau — The Faculty of Letters
              </span>
            </div>

            {/* border */}
          </div>

          {/* border */}
        </div>
      </div>
    </div>
  );
}

export default Education;
