import React from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import img1 from "../../assets/projects/1.jpg";
import img2 from "../../assets/projects/2.jpeg";
import img3 from "../../assets/projects/3.jpeg";
import img4 from "../../assets/projects/5.png";
import img5 from "../../assets/projects/6.jpeg";
import img6 from "../../assets/projects/7.jpg";
import img7 from "../../assets/projects/8.jpg";
import img8 from "../../assets/projects/2.jpg";
import img9 from "../../assets/projects/rent-stad.jpg";
import img10 from "../../assets/projects/shoutitout.se.jpg";
import "./Projects.css";

function ProjectsLife() {
  return (
    <div className="ProjectsComponent pt-5 pb-5 ">
      <h5 className="text-center mb-5 title-life">Commercial projects</h5>
      <div className="cards d-flex flex-wrap ">
          {/* one */}
          <Card style={{ width: "20rem", height: "400px", margin: "auto" }}>
          <div className="image-box">
            <Card.Img className="images-project" variant="top" src={img10} />
          </div>
          <Card.Body className="card-body">
            <Card.Title>Reviews Platform</Card.Title>
            <div className="card-text py-2">
              <ul className="list-group">
                <li>MERN/Typescript</li>
                <li>AWS/JWT/GoogleLogin</li>
                <li>MUI</li>
              </ul>
            </div>
            <Button variant="secondary">
              <a
                href="https://shoutitout.se/"
                target="_blank"
                rel="noreferrer"
                className="project-btn"
              >
                website
              </a>
            </Button>
          </Card.Body>
        </Card>
        {/* one */}
        {/* one */}
        <Card style={{ width: "20rem", height: "400px", margin: "auto" }}>
          <div className="image-box">
            <Card.Img className="images-project" variant="top" src={img1} />
          </div>
          <Card.Body className="card-body">
            <Card.Title>Accounting firm</Card.Title>
            <div className="card-text py-2">
              <ul className="list-group">
                <li>ReactJS</li>
                <li>MUI</li>
              </ul>
            </div>
            <Button variant="secondary">
              <a
                href="https://agloredovisning.se/"
                target="_blank"
                rel="noreferrer"
                className="project-btn"
              >
                website
              </a>
            </Button>
          </Card.Body>
        </Card>
        {/* one */}
        <Card style={{ width: "20rem", height: "400px", margin: "auto" }}>
          <div className="image-box">
            <Card.Img className="images-project" variant="top" src={img2} />
          </div>
          <Card.Body className="card-body">
            <Card.Title>Cleaning firm</Card.Title>
            <div className="card-text py-2">
              <ul className="list-group">
                <li>ReactJS</li>
                <li>Bootstrap/CSS/SCSS</li>
              </ul>
            </div>
            <Button variant="secondary">
              <a
                href="https://svrent.se/"
                target="_blank"
                rel="noreferrer"
                className="project-btn"
              >
                website
              </a>
            </Button>
          </Card.Body>
        </Card>
        {/* one */}
        <Card style={{ width: "20rem", height: "400px", margin: "auto" }}>
          <div className="image-box">
            <Card.Img className="images-project" variant="top" src={img3} />
          </div>
          <Card.Body className="card-body">
            <Card.Title> Beauty Salon</Card.Title>
            <div className="card-text py-2">
              <ul className="list-group">
                <li>ReactJS</li>
                <li>MUI</li>
              </ul>
            </div>
            <Button variant="secondary">
              <a
                href="https://beautyspace.se/"
                target="_blank"
                rel="noreferrer"
                className="project-btn"
              >
                website
              </a>
            </Button>
          </Card.Body>
        </Card>
        {/* one */}
        <Card style={{ width: "20rem", height: "400px", margin: "auto" }}>
          <div className="image-box">
            <Card.Img className="images-project" variant="top" src={img4} />
          </div>
          <Card.Body className="card-body">
            <Card.Title>Constructing firm</Card.Title>
            <div className="card-text py-2">
              <ul className="list-group">
                <li>ReactJS</li>
                <li>Bootstrap/CSS</li>
              </ul>
            </div>
            <Button variant="secondary">
              <a
                href="https://www.aglobygg.se/"
                target="_blank"
                rel="noreferrer"
                className="project-btn"
              >
                website
              </a>
            </Button>
          </Card.Body>
        </Card>
        {/* one */}
        <Card style={{ width: "20rem", height: "400px", margin: "auto" }}>
          <div className="image-box">
            <Card.Img className="images-project" variant="top" src={img5} />
          </div>
          <Card.Body className="card-body">
            <Card.Title>Cleaning firm</Card.Title>
            <div className="card-text py-2">
              <ul className="list-group">
                <li>ReactJS</li>
                <li>Bootstrap/CSS</li>
              </ul>
            </div>
            <Button variant="secondary">
              <a
                href="https://www.viktoriyastad.se/"
                target="_blank"
                rel="noreferrer"
                className="project-btn"
              >
                website
              </a>
            </Button>
          </Card.Body>
        </Card>
        {/* one */}
        <Card style={{ width: "20rem", height: "400px", margin: "auto" }}>
          <div className="image-box">
            <Card.Img className="images-project" variant="top" src={img6} />
          </div>
          <Card.Body className="card-body">
            <Card.Title>Car service</Card.Title>
            <div className="card-text py-2">
              <ul className="list-group">
                <li>ReactJS / Context API</li>
                <li>Bootstrap/CSS</li>
              </ul>
            </div>
            <Button variant="secondary">
              <a
                href="https://cardent.md/"
                target="_blank"
                rel="noreferrer"
                className="project-btn"
              >
                website
              </a>
            </Button>
          </Card.Body>
        </Card>
        {/* one */}

        {/* one */}
        <Card style={{ width: "20rem", height: "400px", margin: "auto" }}>
          <div className="image-box">
            <Card.Img className="images-project" variant="top" src={img8} />
          </div>
          <Card.Body className="card-body">
            <Card.Title className="text-center">
              Car launcher for Android
            </Card.Title>

            <p className="text-center team-text">(team project)</p>
            <div className="card-text py-2">
              <ul className="list-group">
                <li>React Native Expo</li>
              </ul>
            </div>
            <Button variant="secondary">
              <a
                href="https://drive.google.com/file/d/1K9IyPXWXJvudyEpmvL9TmksyS1_6Ag4F/view"
                target="_blank"
                rel="noreferrer"
                className="project-btn"
              >
                download
              </a>
            </Button>
          </Card.Body>
        </Card>
        {/* one */}
        <Card style={{ width: "20rem", height: "400px", margin: "auto" }}>
          <div className="image-box">
            <Card.Img className="images-project" variant="top" src={img9} />
          </div>
          <Card.Body className="card-body">
            <Card.Title className="text-center">Cleaning firm</Card.Title>

            <div className="card-text py-2">
              <ul className="list-group">
                <li> ReactJS</li>
                <li> Bootstrap/CSS</li>
              </ul>
            </div>
            <Button variant="secondary">
              <a
                href="https://rent-stad.se/"
                target="_blank"
                rel="noreferrer"
                className="project-btn"
              >
                website
              </a>
            </Button>
          </Card.Body>
        </Card>
        {/* one */}
        <Card style={{ width: "20rem", height: "400px", margin: "auto" }}>
          <div className="image-box">
            <Card.Img className="images-project" variant="top" src={img7} />
          </div>
          <Card.Body className="card-body">
            <Card.Title> Gravestone Restoration Service</Card.Title>
            <div className="card-text py-2">
              <ul className="list-group">
                <li>ReactJS</li>
                <li>EmailJS</li>
                <li>Bootstrap/CSS</li>
              </ul>
            </div>
            <Button variant="secondary">
              <a
                href="https://gravstens.se/"
                target="_blank"
                rel="noreferrer"
                className="project-btn"
              >
                website
              </a>
            </Button>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}

export default ProjectsLife;
