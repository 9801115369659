import React from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";

import "./ProjectsSchool.css";

function ProjectsSchool() {
  return (
    <div className="projectsSchool-container">
      <h5 className="text-center mb-5 title-school ">Latest projects</h5>
      <div className="cards-school d-flex flex-wrap ">
        {/* one */}

        <div className="row m-auto">
          <div className="col col-f p-0">
            <Card
              className="card-school"
              style={{
                width: "14rem",
                height: "250px",
                margin: "auto",
              }}
            >
              <Card.Body>
                <Card.Title className="text-center">
                  Front-end project
                </Card.Title>
                <div className="card-text py-2">
                  <ul className="list-group">
                    <li>ReactJS</li>
                    <li>Typescript</li>
                    <li>Redux</li>
                    <li>API</li>
                    <li>MUI</li>
                  </ul>
                </div>
                <div className="buttn">
                  <Button className="mx-1" variant="secondary">
                    <a
                      href="https://front-end-alina.netlify.app/"
                      target="_blank"
                      rel="noreferrer"
                      className="project-btn"
                    >
                      Netlify
                    </a>
                  </Button>

                  <Button className="mx-1" variant="secondary">
                    <a
                      href="https://github.com/AlinaCGM/Front-End_Project"
                      target="_blank"
                      rel="noreferrer"
                      className="project-btn"
                    >
                      GitHub
                    </a>
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </div>
          <div className="col col-s p-0">
            <Card
              className="card-school"
              style={{
                width: "14rem",
                height: "250px",
                margin: "auto",
              }}
            >
              <div className="img-box"></div>
            </Card>
          </div>
        </div>

        {/* one */}
        <div className="row m-auto">
          <div className="col col-f p-0">
            <Card
              className="card-school"
              style={{
                width: "14rem",
                height: "250px",
                margin: "auto",
              }}
            >
              <Card.Body>
                <Card.Title className="text-center">Todo app</Card.Title>
                <div className="card-text py-2">
                  <ul className="list-group">
                    <li>ReactJS</li>
                    <li>CSS</li>
                  </ul>
                </div>
                <div className="buttn">
                  <Button className="mx-1" variant="secondary">
                    <a
                      href="https://todo-samoteev.netlify.app"
                      target="_blank"
                      rel="noreferrer"
                      className="project-btn"
                    >
                      Netlify
                    </a>
                  </Button>

                  <Button className="mx-1" variant="secondary">
                    <a
                      href="https://github.com/AlinaCGM/react_todo"
                      target="_blank"
                      rel="noreferrer"
                      className="project-btn"
                    >
                      GitHub
                    </a>
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </div>
          <div className="col col-s p-0">
            <Card
              className="card-school"
              style={{
                width: "14rem",
                height: "250px",
                margin: "auto",
              }}
            >
              <div className="img-box2"></div>
            </Card>
          </div>
        </div>
        {/* one!!! */}
        <div className="row m-auto">
          <div className="col col-f p-0">
            <Card
              className="card-school"
              style={{
                width: "14rem",
                height: "250px",
                margin: "auto",
              }}
            >
              <Card.Body>
                <Card.Title className="text-center">Yummy-project</Card.Title>
                <div className="card-text py-2">
                  <ul className="list-group">
                    <li>ReactJS</li>
                    <li>Typescript</li>
                    <li>API</li>
                    <li>MUI</li>
                  </ul>
                </div>
                <div className="buttn">
                  <Button className="mx-1" variant="secondary">
                    <a
                      href="https://chimerical-unicorn-d9f4e5.netlify.app/"
                      target="_blank"
                      rel="noreferrer"
                      className="project-btn"
                    >
                      Netlify
                    </a>
                  </Button>

                  <Button className="mx-1" variant="secondary">
                    <a
                      href="https://github.com/AlinaCGM/Yummy-project"
                      target="_blank"
                      rel="noreferrer"
                      className="project-btn"
                    >
                      GitHub
                    </a>
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </div>
          <div className="col col-s p-0">
            <Card
              className="card-school"
              style={{
                width: "14rem",
                height: "250px",
                margin: "auto",
              }}
            >
              <div className="img-box3"></div>
            </Card>
          </div>
        </div>

        {/* one!!! */}

        <div className="row m-auto">
          <div className="col col-f p-0">
            <Card
              className="card-school"
              style={{
                width: "14rem",
                height: "250px",
                margin: "auto",
              }}
            >
              <Card.Body>
                <Card.Title className="text-center">E-commerce</Card.Title>
                <p className="text-center team-text">(team project)</p>
                <div className="card-text py-2">
                  <ul className="list-group">
                    <li>ReactJS</li>
                    <li>Typescript</li>
                    <li>Redux</li>
                    <li>API /localStorage</li>
                    <li>MUI</li>
                  </ul>
                </div>
                <div className="buttn">
                  <Button className="mx-1" variant="secondary">
                    <a
                      href="https://63ca912053d3ea0008524434--gleeful-pothos-81eed6.netlify.app/"
                      target="_blank"
                      rel="noreferrer"
                      className="project-btn"
                    >
                      Netlify
                    </a>
                  </Button>

                  <Button className="mx-1" variant="secondary">
                    <a
                      href="https://github.com/AlinaCGM/ecommteam4"
                      target="_blank"
                      rel="noreferrer"
                      className="project-btn"
                    >
                      GitHub
                    </a>
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </div>
          <div className="col col-s p-0">
            <Card
              className="card-school"
              style={{
                width: "14rem",
                height: "250px",
                margin: "auto",
              }}
            >
              <div className="img-box4"></div>
            </Card>
          </div>
        </div>

        {/* one!!! */}
        <div className="row m-auto">
          <div className="col col-f p-0">
            <Card
              className="card-school"
              style={{
                width: "14rem",
                height: "250px",
                margin: "auto",
              }}
            >
              <Card.Body>
                <Card.Title className="text-center">
                  Fullstack E-commerce
                </Card.Title>

                <div className="card-text py-2">
                  <ul className="list-group">
                    <li>ReactJS</li>
                    <li>Typescript</li>
                    <li>NodeJS</li>
                    <li>ExpressJS</li>
                    <li>MUI</li>
                  </ul>
                </div>
                <div className="buttn">
                  <Button className="mx-1" variant="secondary">
                    <a
                      href="https://github.com/AlinaCGM/Connect_BEandFE"
                      target="_blank"
                      rel="noreferrer"
                      className="project-btn"
                    >
                      GitHub
                    </a>
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </div>
          <div className="col col-s p-0">
            <Card
              className="card-school"
              style={{
                width: "14rem",
                height: "250px",
                margin: "auto",
              }}
            >
              <div className="img-box5"></div>
            </Card>
          </div>
        </div>

        {/* one!!! */}
        <div className="row m-auto">
          <div className="col col-f p-0">
            <Card
              className="card-school"
              style={{
                width: "14rem",
                height: "250px",
                margin: "auto",
              }}
            >
              <Card.Body>
                <Card.Title className="text-center">Fullstack</Card.Title>

                <div className="card-text py-2">
                  <ul className="list-group">
                    <li>MERN</li>
                    <li>Redux Toolkit</li>
                    <li>MUI</li>
                  </ul>
                </div>
                <div className="buttn">
                  <Button className="mx-1" variant="secondary">
                    <a
                      href="https://mobilic-front-end.onrender.com/"
                      target="_blank"
                      rel="noreferrer"
                      className="project-btn"
                    >
                      Render
                    </a>
                  </Button>
                  <Button className="mx-1" variant="secondary">
                    <a
                      href="https://github.com/AlinaCGM/FullStackMongoDB"
                      target="_blank"
                      rel="noreferrer"
                      className="project-btn"
                    >
                      GitHub
                    </a>
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </div>
          <div className="col col-s p-0">
            <Card
              className="card-school"
              style={{
                width: "14rem",
                height: "250px",
                margin: "auto",
              }}
            >
              <div className="img-box6"></div>
            </Card>
          </div>
        </div>
        {/* one!!! */}
        <div className="row m-auto">
          <div className="col col-f p-0">
            <Card
              className="card-school"
              style={{
                width: "14rem",
                height: "250px",
                margin: "auto",
              }}
            >
              <Card.Body>
                <Card.Title className="text-center">Slot Game</Card.Title>

                <div className="card-text py-2">
                  <ul className="list-group">
                    <li>Pixi.JS</li>
                    <li>Typescript</li>
                    <li>NodeJS</li>
                    <li>ExpressJS</li>
                  </ul>
                </div>
                <div className="buttn">
                  <Button className="mx-1" variant="secondary">
                    <a
                      href="https://github.com/AlinaCGM/slotGame/blob/master/README.md"
                      target="_blank"
                      rel="noreferrer"
                      className="project-btn"
                    >
                      GitHub
                    </a>
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </div>
          <div className="col col-s p-0">
            <Card
              className="card-school"
              style={{
                width: "14rem",
                height: "250px",
                margin: "auto",
              }}
            >
              <div className="img-box8"></div>
            </Card>
          </div>
        </div>
        <div className="row m-auto">
          <div className="col col-f p-0">
            <Card
              className="card-school"
              style={{
                width: "14rem",
                height: "250px",
                margin: "auto",
              }}
            >
              <Card.Body>
                <Card.Title className="text-center">
                  Frontend project
                </Card.Title>

                <div className="card-text py-2">
                  <ul className="list-group">
                    <li>ReactJS</li>
                    <li>API</li>
                    <li>MUI</li>
                  </ul>
                </div>
                <div className="buttn">
                  <Button className="mx-1" variant="secondary">
                    <a
                      href="https://codesandbox.io/s/wandering-feather-m95n55?file"
                      target="_blank"
                      rel="noreferrer"
                      className="project-btn"
                    >
                      Codesandbox
                    </a>
                  </Button>
                  <Button className="mx-1" variant="secondary">
                    <a
                      href="https://github.com/AlinaCGM/dev_app_api"
                      target="_blank"
                      rel="noreferrer"
                      className="project-btn"
                    >
                      GitHub
                    </a>
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </div>
          <div className="col col-s p-0">
            <Card
              className="card-school"
              style={{
                width: "14rem",
                height: "250px",
                margin: "auto",
              }}
            >
              <div className="img-box9"></div>
            </Card>
          </div>
        </div>
        {/* one!!! */}
        <div className="row m-auto">
          <div className="col col-f p-0">
            <Card
              className="card-school"
              style={{
                width: "14rem",
                height: "250px",
                margin: "auto",
              }}
            >
              <Card.Body>
                <Card.Title className="text-center">
                  Fullstack FoodBlog
                </Card.Title>
                <p className="text-center team-text">(work is in progress)</p>
                <div className="card-text py-2">
                  <ul className="list-group">
                    <li>MERN</li>
                    <li>Typescript</li>
                    <li>Redux</li>
                    <li>MUI</li>
                    <li>Google login</li>
                  </ul>
                </div>
                <div className="buttn">
                  <Button className="mx-1" variant="secondary">
                    <a
                      href="https://github.com/AlinaCGM/Blog"
                      target="_blank"
                      rel="noreferrer"
                      className="project-btn"
                    >
                      GitHub
                    </a>
                  </Button>
                  <Button className="mx-1" variant="secondary">
                    <a
                      href="https://blog-client-wz90.onrender.com/"
                      target="_blank"
                      rel="noreferrer"
                      className="project-btn"
                    >
                      Render
                    </a>
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </div>
          <div className="col col-s p-0">
            <Card
              className="card-school"
              style={{
                width: "14rem",
                height: "250px",
                margin: "auto",
              }}
            >
              <div className="img-box7"></div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectsSchool;
