import React from "react";

import "./Personal.css";

function Personal() {
  return (
    <div>
      <div className="about-container pb-5 curved-background__curved">
        <div className="row mx-auto text-center  ">
          <div className="col text-white first">
            <h1 className="title-about">About Me</h1>
            <p>
              As a skilled Fullstack Developer with over 3 years of experience,
              I bring a passion for cutting-edge technologies and a laser focus
              on delivering exceptional user experiences. I relish the
              opportunity to collaborate with talented teams, sharing my
              expertise while continually learning from others.
            </p>
            <p>
              In my free time, you'll find me eagerly tinkering with new web app
              projects and expanding my tech horizons with the latest stacks and
              techniques. Whether it's exploring the great outdoors, camping,
              fishing or traveling across Europe, I love soaking up every moment
              with my family and friends.
            </p>

            <p>
              I'm thrilled at the prospect of joining a team that values
              creativity, innovation, and excellence in all aspects of web
              development. Let's create amazing user experiences together!
            </p>
          </div>
          <div className="col text-white second">
            <div className=" picture"></div>
          </div>
        </div>
        <a
          className="download"
          href="Resume.pdf"
          target="_blank"
          rel="noreferrer"
        >
          Download CV
        </a>
      </div>
    </div>
  );
}

export default Personal;
