import React from "react";
import { Link } from "react-router-dom";

import Weather from "../../components/widget/Weather";
import { AiOutlineLinkedin, AiOutlineGithub } from "react-icons/ai";
import "./Home.css";

function Home() {
  return (
    <div className="home ">
      <div className="title ">
        <h1 className="name">Alina </h1>
        <h1 className="lastname">SAMOTEEV</h1>
        <h2 className="subTitle">Full-stack Developer</h2>

        <div className="links">
          <Link to="/about" className="link">
            Resume
          </Link>
          <Link to="/projects" className="link">
            Projects
          </Link>
          <div className="icon-box">
            <span className="icon">
              <a
                href="https://www.linkedin.com/in/alina-samoteev-627836a5/"
                target="_blank"
                rel="noreferrer"
                className="links-style"
              >
                <AiOutlineLinkedin size="30px" />
              </a>
            </span>
            <span className="icon">
              <a
                href="https://github.com/AlinaCGM"
                target="_blank"
                rel="noreferrer"
                className="links-style"
              >
                <AiOutlineGithub size="30px" />
              </a>
            </span>
          </div>
        </div>
      </div>
      <div className="weather">
        <Weather />
      </div>
    </div>
  );
}

export default Home;
