import { Routes, Route } from "react-router-dom";

import "./App.css";
import NavbarFixed from "./components/navbar/NavbarFixed";
import Footer from "./components/footer/Footer";
import About from "./pages/about/About";
import Home from "./pages/home/Home";
import Projects from "./pages/projects/Projects";

function App() {
  return (
    <div>
      <NavbarFixed />
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/projects" element={<Projects />}></Route>
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
