import React from "react";
import ProjectsLife from "../../components/projects/ProjectsLife";
import ProjectsSchool from "../../components/projects/ProjectsSchool";
import "../../App.css";

function Projects() {
  return (
    <div className="projects-main">
      <ProjectsLife />
      <ProjectsSchool />
    </div>
  );
}

export default Projects;
