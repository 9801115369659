import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <div className=" footer-container text-center pt-1 ">
      <div className="d-flex justify-content-evenly  ms-auto ">
        <div>Stockholm @ 2023</div>
      </div>
    </div>
  );
}

export default Footer;
