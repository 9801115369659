// import React from "react";
// import Education from "../education/Education";

// import "./Skills.css";

// function Skills() {
//   return (
//     <div className="skills-container pt-5 pb-5">
//       <div className="row">
//         {/* first col */}
//         <div className="col-12 skills-col ">
//           {/* <h5 className="title-skills text-center mt-0"> FrontEnd</h5> */}
//           {/* table start */}
//           <div className="row border-col ">
//             <div className="col  text-center">
//               <h5 className="title-skills  mt-0 mb-3"> FrontEnd</h5>
//               <div className="cells">JavaScript</div>
//               <div className="cells">ReactJS/React Native</div>
//               <div className="cells">TypeScript</div>
//               <div className="cells">PixiJS</div>
//               <div className="cells">Django</div>
//               <div className="cells">Redux</div>
//               <div className="cells"></div>
//               <div className="cells">UX/UI,MUI,Styled components</div>
//               <div className="cells">Bootstrap/Tailwind</div>
//             </div>
//             <div className="col border-col text-center">
//               <h5 className="title-skills  mt-0 mb-3"> Backend</h5>
//               <div className="cells">Node.js</div>
//               <div className="cells">Express</div>
//               <div className="cells">PassportJs</div>
//               <div className="cells">MongoDB</div>
//               <div className="cells">SQLite</div>
//               <div className="cells">PostgresSQL</div>
//               <div className="cells">REST API</div>
//               <div className="cells">Linux environment</div>
             
//             </div>
//             <div className="col border-col text-center">
//               <h5 className="title-skills  mt-0 mb-3"> Tools/Other</h5>
//               <div className="cells">Bash/Docker/CI/CD</div>
//               <div className="cells">AWS</div>
//               <div className="cells">GitHub</div>
//               <div className="cells">Git</div>
//               <div className="cells">Figma</div>
//               <div className="cells">Netlify</div>
//               <div className="cells">Render</div>
//               <div className="cells">API Testing:Postman</div>
//             </div>
//           </div>

//           {/* table end */}
//           <div className="indicators ">
//             <h5 className="title-skills text-center  mt-5">Language Skills</h5>
//             <div className="lang-skills text-center my-5 ">
//               {/* border */}
//               <div className="  progress-one ">
//                 <label className="indicator text-left" htmlFor="file">
//                   Russian
//                 </label>
//                 <progress id="file" max="100" value="100"></progress>
//               </div>
//               {/* border */}
//               <div className=" progress-one ">
//                 <label className="indicator text-left" htmlFor="file">
//                   Romanian
//                 </label>
//                 <progress id="file" max="100" value="80"></progress>
//               </div>
//               {/* border */}
//               <div className=" progress-one ">
//                 <label className="indicator text-left" htmlFor="file">
//                   English
//                 </label>
//                 <progress id="file" max="100" value="80"></progress>
//               </div>
//               {/* border */}
//               <div className=" progress-one ">
//                 <label className="indicator text-left" htmlFor="file">
//                   Swedish
//                 </label>
//                 <progress id="file" max="100" value="30"></progress>
//               </div>
//             </div>
//           </div>
//         </div>

//         {/*  end of col */}
//         <Education />
//         {/* third col */}
//       </div>
//     </div>
//   );
// }

// export default Skills;

import React from "react";
import Education from "../education/Education";
import "./Skills.css";

function Skills() {
  return (
    <div className="skills-container pt-5 pb-5">
      <div className="row">
        {/* first col */}
        <div className="col-12 skills-col">
          {/* table start */}
          <div className="row border-col">
            <div className="col text-center">
              <h5 className="title-skills mt-0 mb-3">FrontEnd</h5>
              <div className="cells">JavaScript</div>
              <div className="cells">ReactJS/React Native</div>
              <div className="cells">TypeScript</div>
              <div className="cells">PixiJS</div>
              <div className="cells">Redux</div>
              <div className="cells">UX/UI, MUI, Styled components</div>
              <div className="cells">Bootstrap/Tailwind</div>
            </div>
            <div className="col border-col text-center">
              <h5 className="title-skills mt-0 mb-3">Backend</h5>
              <div className="cells">Node.js/Express</div>
              <div className="cells">PassportJs</div>
              <div className="cells">MongoDB</div>
              <div className="cells">Django/SQLite</div>
              <div className="cells">PostgresSQL</div>
              <div className="cells">REST API</div>
              <div className="cells">Linux environment</div>
            </div>
            <div className="col border-col text-center">
              <h5 className="title-skills mt-0 mb-3">Tools/Other</h5>
              <div className="cells">Bash</div>
              <div className="cells">AWS</div>
              <div className="cells">Netlify/Render</div>
              <div className="cells">GitHub</div>
              <div className="cells">Git</div>
              <div className="cells">Figma</div>
              <div className="cells">API Testing: Postman</div>
            </div>
          </div>
          {/* table end */}
          <div className="indicators">
            <h5 className="title-skills text-center mt-5">Language Skills</h5>
            <div className="lang-skills text-center my-5">
              {/* border */}
              <div className="progress-one">
                <label className="indicator text-left" htmlFor="file">
                  Russian
                </label>
                <progress id="file" max="100" value="100"></progress>
              </div>
              {/* border */}
              <div className="progress-one">
                <label className="indicator text-left" htmlFor="file">
                  Romanian
                </label>
                <progress id="file" max="100" value="80"></progress>
              </div>
              {/* border */}
              <div className="progress-one">
                <label className="indicator text-left" htmlFor="file">
                  English
                </label>
                <progress id="file" max="100" value="80"></progress>
              </div>
              {/* border */}
              <div className="progress-one">
                <label className="indicator text-left" htmlFor="file">
                  Swedish
                </label>
                <progress id="file" max="100" value="30"></progress>
              </div>
            </div>
          </div>
        </div>
        {/* end of col */}
        <Education />
        {/* third col */}
      </div>
    </div>
  );
}

export default Skills;
